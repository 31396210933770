export const getMenuData = [
  {
    category: true,
    title: 'Dashboard',
    roles: ['admin', 'perawat', 'dokter'],
  },
  {
    title: 'Home',
    key: 'dashboard',
    icon: 'fas fa-hospital',
    url: '/dashboard',
    roles: ['admin', 'perawat', 'dokter'],
    // children: [
    //   {
    //     title: 'Dashboard Umum',
    //     key: 'dashboardUmum',
    //     url: '/dashboard/umum',
    //     roles: ['all'],
    //   },
    // ],
  },
  // {
  //   category: true,
  //   title: 'Entitas',
  // },
  // {
  //   title: 'Pasien',
  //   key: 'Pasien',
  //   icon: 'fas fa-users',
  //   url: '/pasien',
  //   roles: ['all'],
  // },
  // {
  //   title: 'Resume',
  //   key: 'Resume',
  //   icon: 'fas fa-file-alt',
  //   url: '/verifikator',
  //   roles: ['admin', 'verifikator', 'astrazeneca'],
  // },
  {
    title: 'Pasien',
    key: 'Pasien',
    icon: 'fas fa-users',
    roles: ['admin', 'perawat'],
    children: [
      {
        title: 'Daftar Semua Pasien',
        key: 'Daftar Semua Pasien',
        // icon: 'fe fe-list',
        url: '/pasien',
        roles: ['admin', 'perawat'],
      },
      {
        title: 'Form Registrasi Pasien Baru',
        key: 'Form Registrasi Pasien Baru',
        // icon: 'fe fe-plus',
        url: '/pasien/tambah',
        roles: ['admin', 'perawat'],
      },
    ],
  },
  {
    title: 'Daftar Pasien',
    key: 'Pasien-dokter',
    icon: 'fas fa-users',
    url: '/pasien',
    roles: ['dokter'],
  },
  {
    title: 'Daftar Pemeriksaan',
    key: 'Pemeriksaan Perawat',
    icon: 'fas fa-medkit',
    url: '/pemeriksaan/perawat',
    roles: ['admin', 'perawat'],
  },
  {
    title: 'Daftar Pemeriksaan',
    key: 'Pemeriksaan Dokter',
    icon: 'fas fa-file-medical',
    url: '/pemeriksaan/dokter',
    roles: ['admin', 'dokter'],
  },
  // {
  //   title: 'Dokter',
  //   key: 'Dokter',
  //   icon: 'fas fa-user-nurse',
  //   url: '/dokter',
  //   roles: ['admin', 'astrazeneca'],
  //   // children: [
  //   //   {
  //   //     title: 'List Dokter',
  //   //     key: 'List Dokter',
  //   //     icon: 'fe fe-list',
  //   //     url: '/dokter',
  //   //   },
  //   //   {
  //   //     title: 'Tambah Dokter',
  //   //     key: 'Tambah Dokter',
  //   //     icon: 'fe fe-plus',
  //   //     url: '/dokter/tambah',
  //   //   },
  //   // ],
  // },
  // {
  //   title: 'Formulir',
  //   key: 'Dokter',
  //   icon: 'fas fa-file',
  //   url: '/formulir',
  //   // roles: ['admin', 'astrazeneca'],
  //   // children: [
  //   //   {
  //   //     title: 'List Dokter',
  //   //     key: 'List Dokter',
  //   //     icon: 'fe fe-list',
  //   //     url: '/dokter',
  //   //   },
  //   //   {
  //   //     title: 'Tambah Dokter',
  //   //     key: 'Tambah Dokter',
  //   //     icon: 'fe fe-plus',
  //   //     url: '/dokter/tambah',
  //   //   },
  //   // ],
  // },
  // {
  //   title: 'Hasil Pemeriksaan',
  //   key: 'Farmasi',
  //   icon: 'fas fa-file-prescription',
  //   url: '/farmasi',
  //   roles: ['admin', 'astrazeneca'],
  // },
  // {
  //   title: 'Hasil Konferensi',
  //   key: 'Laboratorium',
  //   icon: 'fas fa-file-medical-alt',
  //   url: '/laboratorium',
  //   roles: ['admin', 'astrazeneca'],
  // },
  // {
  //   category: true,
  //   title: 'Program',
  // },
  // {
  //   title: 'Konferensi',
  //   key: 'Obat',
  //   icon: 'fas fa-refresh',
  //   url: '/obat',
  //   roles: ['admin', 'astrazeneca'],
  // },
  // {
  //   title: 'Voucher',
  //   key: 'Voucher',
  //   icon: 'fas fa-ticket-alt',
  //   url: '/voucher',
  //   roles: ['admin', 'astrazeneca'],
  // },
  // {
  //   title: 'Artikel',
  //   key: 'Artikel',
  //   icon: 'far fa-newspaper',
  //   url: '/artikel',
  //   roles: ['admin', 'astrazeneca'],
  // },
  // {
  //   category: true,
  //   title: 'Survey',
  // },
  // {
  //   title: 'List Pasien',
  //   key: 'Pasien',
  //   icon: 'fas fa-users',
  //   url: '/pasien',
  //   // roles: ['all'],
  // },
  // {
  //   title: 'Pertanyaan Survey',
  //   key: 'PertanyaanSurvey',
  //   icon: 'fa fa-poll',
  //   url: '/survey',
  //   roles: ['admin', 'astrazeneca'],
  // },
]

// {/* <i class="fa-solid fa-square-poll-horizontal"></i> */}
