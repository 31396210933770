import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import * as firebase from '@/services/firebase'
import * as jwt from '@/services/jwt'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.loginNew,
    loginGoogle: jwt.loginGoogle,
    loginverificator: jwt.loginverificator,
    register: jwt.register,
    registerGoogle: jwt.registerGoogle,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    role: '',
    email: '',
    avatar: '',
    admin: false,
    authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
    loading: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async LOGIN({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload
      commit('SET_STATE', {
        loading: true,
      })

      let isSuccess = null
      const login = mapAuthProviders[rootState.settings.authProvider].login
      // console.log("login",login)
      const role = 'dashboard'
      await login(email, password, role).then((success) => {
        if (success) {
          // console.log("berhasil login")
          // console.log("isinya apa?",success)
          // console.log("role all",success.user.roles)
          // console.log("role 0",success.user.roles[0])
          let admin = false
          if (success.user.roles[0] === 'admin') {
            admin = true
          }
          commit('SET_STATE', {
            id: success.user.id,
            name: success.user.name,
            email: success.user.email,
            avatar: '',
            role: success.user.roles[0],
            authorized: true,
            admin: admin,
          })
          window.localStorage.setItem('id', success.user.id)
          window.localStorage.setItem('email', success.user.email)
          window.localStorage.setItem('name', success.user.name)
          window.localStorage.setItem('role', success.user.roles[0])
          window.localStorage.setItem('allRoles', success.user.roles)
          window.localStorage.setItem('authorized', true)
          window.localStorage.setItem('admin', admin)
          isSuccess = true
          // router.push('/dashboard')
          // dispatch('LOAD_CURRENT_ACCOUNT')
          // Vue.prototype.$notification.success({
          //   message: 'Logged In',
          //   description: 'You have successfully logged in to Air UI Vue Admin Template!',
          // })
        }
        if (!success) {
          // console.log("gagal login")
          commit('SET_STATE', {
            loading: false,
          })
          isSuccess = false
        }
      })
      return isSuccess
    },
    LOGIN_GOOGLE({ commit, dispatch, rootState }, { payload }) {
      const { credential } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const loginGoogle = mapAuthProviders[rootState.settings.authProvider].loginGoogle
      loginGoogle(credential).then((success) => {
        if (success) {
          let isAdmin = false
          window.localStorage.setItem('googleCredential', credential)
          if (success.newAccount === false){
            if (success.roles[0] === 'admin') {
              isAdmin = true
            }
            commit('SET_STATE', {
              id: success.id,
              name: success.name,
              email: success.email,
              avatar: '',
              role: success.roles[0],
              allRoles: success.roles,
              authorized: true,
              admin: isAdmin,
            })
            window.localStorage.setItem('id', success.id)
            window.localStorage.setItem('email', success.email)
            window.localStorage.setItem('name', success.name)
            window.localStorage.setItem('role', success.roles[0])
            window.localStorage.setItem('allRoles', success.roles)  
            window.localStorage.setItem('authorized', true)
            window.localStorage.setItem('admin', isAdmin)
            router.push('/')
          } else {
            router.push('/auth/registerFromGoogleAccount/')
          }

          // router.push('/dashboard')
          // dispatch('LOAD_CURRENT_ACCOUNT')
          // Vue.prototype.$notification.success({
          //   message: 'Logged In',
          //   description: 'You have successfully logged in to Air UI Vue Admin Template!',
          // })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    LOGINVERIFICATOR({ commit, dispatch, rootState }, { payload }) {
      const { email, password } = payload
      commit('SET_STATE', {
        loading: true,
      })

      const login =
        mapAuthProviders[rootState.settings.authProvider].loginverificator
      login(email, password).then((success) => {
        if (success) {
          console.log(success)
          commit('SET_STATE', {
            id: success.user.id,
            name: success.user.name,
            email: success.user.email,
            avatar: '',
            role: 'Verificator',
            authorized: true,
            admin: false,
          })
          window.localStorage.setItem('id', success.user.id)
          window.localStorage.setItem('email', success.user.email)
          window.localStorage.setItem('name', success.user.name)
          window.localStorage.setItem('role', success.user.roles[0])
          window.localStorage.setItem('allRoles', success.user.roles)
          window.localStorage.setItem('authorized', true)
          window.localStorage.setItem('admin', false)
          router.push('/')
          // dispatch('LOAD_CURRENT_ACCOUNT')
          // Vue.prototype.$notification.success({
          //   message: 'Logged In',
          //   description: 'You have successfully logged in to Air UI Vue Admin Template!',
          // })
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
        }
      })
    },
    async REGISTER({ commit, dispatch, rootState }, { payload }) {
      // eslint-disable-next-line camelcase
      const { email, password, name, roles, medical_facility_id, phone_no, specialization_id } = payload
      commit('SET_STATE', {
        loading: true,
      })

      let isSuccess = null

      const register =
        mapAuthProviders[rootState.settings.authProvider].register
      await register(email, password, name, roles, medical_facility_id, phone_no, specialization_id).then((success) => {
        if (success) {
          // dispatch('LOAD_CURRENT_ACCOUNT')
          Vue.prototype.$notification.success({
            message: 'Registrasi Berhasil',
            description: 'Berhasil meregistrasi akun Anda!',
          })
          router.push('/auth/login')
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
          isSuccess = false
          // console.log("gagal register")
        }
      })
      return isSuccess
    },
    async REGISTER_GOOGLE({ commit, dispatch, rootState }, { payload, id }) {
      // eslint-disable-next-line camelcase
      const { email, password, name, roles, medical_facility_id, phone_no, id_token, specialization_id } = payload
      commit('SET_STATE', {
        loading: true,
      })

      let isSuccess = null
      const registerGoogle =
        mapAuthProviders[rootState.settings.authProvider].registerGoogle
      await registerGoogle(id, password, name, roles, medical_facility_id, phone_no, id_token, specialization_id).then((success) => {
        if (success) {
          // dispatch('LOAD_CURRENT_ACCOUNT')
          let isAdmin = false
          if (roles[0] === 'admin') {
            isAdmin = true
          }
          commit('SET_STATE', {
            id: id,
            name: name,
            email: email,
            avatar: '',
            role: roles[0],
            allRoles: roles,
            authorized: true,
            admin: isAdmin,
          })
          window.localStorage.setItem('id', id)
          window.localStorage.setItem('email', email)
          window.localStorage.setItem('name', name)
          window.localStorage.setItem('role', roles[0])
          window.localStorage.setItem('allRoles', roles)
          window.localStorage.setItem('authorized', true)
          window.localStorage.setItem('admin', isAdmin)
          Vue.prototype.$notification.success({
            message: 'Registrasi Berhasil',
            description: 'Berhasil meregistrasi akun Anda!',
          })
          router.push('/')
        }
        if (!success) {
          commit('SET_STATE', {
            loading: false,
          })
          isSuccess = false
        }
      })
      return isSuccess
    },
    LOAD_CURRENT_ACCOUNT({ commit, rootState }) {
      commit('SET_STATE', {
        loading: true,
      })
      const id = window.localStorage.getItem('id')
      const email = window.localStorage.getItem('email')
      const name = window.localStorage.getItem('name')
      const role = window.localStorage.getItem('role')
      const authorized = window.localStorage.getItem('authorized')
      const admin = window.localStorage.getItem('admin')
      if (authorized && id) {
        commit('SET_STATE', {
          id: id,
          name: name,
          email: email,
          avatar: '',
          role: role,
          authorized: authorized,
          admin: admin,
        })
      }
      commit('SET_STATE', {
        loading: false,
      })
    },
    LOGOUT({ commit, rootState }) {
      const logout = mapAuthProviders[rootState.settings.authProvider].logout
      window.localStorage.clear()
      logout().then(() => {
        commit('SET_STATE', {
          id: '',
          name: '',
          role: '',
          email: '',
          avatar: '',
          authorized: false,
          loading: false,
        })
        router.push('/auth/login')
      })
    },
  },
  getters: {
    user: (state) => state,
  },
}
