<template>
  <a-layout-sider
    :width="settings.leftMenuWidth"
    :collapsible="settings.isMobileView ? false : true"
    :collapsed="settings.isMenuCollapsed && !settings.isMobileView"
    @collapse="onCollapse"
    :class="{
      [$style.menu]: true,
      [$style.white]: settings.menuColor === 'white',
      [$style.gray]: settings.menuColor === 'gray',
      [$style.dark]: settings.menuColor === 'dark',
      [$style.unfixed]: settings.isMenuUnfixed,
      [$style.shadow]: settings.isMenuShadow,
    }"
  >
    <div
      :class="$style.menuOuter"
      :style="{
        width:
          settings.isMenuCollapsed && !settings.isMobileView
            ? '80px'
            : settings.leftMenuWidth + 'px',
        height:
          settings.isMobileView || settings.isMenuUnfixed
            ? 'calc(100% - 64px)'
            : 'calc(100% - 110px)',
      }"
    >
      <div :class="$style.logoContainer">
        <div :class="$style.logo">
          <img
            v-if="!settings.isMenuCollapsed"
            src="resources/images/medisync-withtext.svg"
            class="mr-2"
            alt="Medisync"
            height="45"
          />
          <img
            v-if="settings.isMenuCollapsed"
            src="resources/images/medisync-notext.svg"
            class="mr-2"
            alt="Medisync"
            height="28"
          />
        </div>
      </div>
      <div
        :style="{
          overflow: auto,
        }"
      >
        <a-menu
          forceSubMenuRender
          :inlineCollapsed="
            settings.isMobileView ? false : settings.isMenuCollapsed
          "
          :mode="'inline'"
          :selectedKeys="selectedKeys"
          :openKeys.sync="openKeys"
          @click="handleClick"
          @openChange="handleOpenChange"
          :inlineIndent="15"
          :class="$style.navigation"
        >
          <template v-for="(item, index) in menuData">
            <template>
              <a-menu-item-group :key="index" v-if="item.category">
                <template slot="title">{{ item.title }}</template>
              </a-menu-item-group>
              <item
                v-if="!item.children && !item.category"
                :menu-info="item"
                :styles="$style"
                :key="item.key"
                :roles="item.roles"
                :user="user"
              />
              <sub-menu
                v-if="item.children"
                :menu-info="item"
                :styles="$style"
                :key="item.key"
                :roles="item.roles"
                :user="user"
              />
            </template>
          </template>
        </a-menu>
        <!-- <div :class="$style.banner">
          <p>More components, more style, more themes, and premium support!</p>
          <a
            href="https://themeforest.net/item/clean-ui-react-admin-template/21938700"
            target="_blank"
            rel="noopener noreferrer"
            class="btn btn-sm btn-success btn-rounded px-3"
          >Buy Bundle</a>
        </div> -->
      </div>
      <a-divider></a-divider>
      <a-dropdown :trigger="['click']" placement="topCenter" class="mr-5">
        <div style="margin-left:30px; width: 203px; height: 40px;">
          <div class="d-flex align-items-center justify-content-between" style="width: 62vw; height: 40px;">
            <div :class="[$style.name, $style.dropdown]">{{ this.user_name }}</div>
            <!-- <img
              src="resources/images/user-icon.svg"
              alt="avatar"
              width="25"
              height="25"
              :class="$style.avatar"
            /> -->
          </div>
        </div>
        <a-menu slot="overlay">
          <a-menu-item>
            <a href="javascript: void(0);" @click="logout">
              <i class="fe fe-log-out mr-2"></i>
              {{ $t('topBar.profileMenu.logout') }}
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>

      <a-dropdown :trigger="['click']" placement="bottomLeft" class="mr-5">
        <div style="margin-left:30px; width: 203px; height: 40px;">
            <div class="d-flex align-items-center">
              <div :class="[$style.name, $style.dropdown]">sebagai {{ this.role }} </div>
            </div>
        </div>
        <a-menu slot="overlay">
          <a-menu-item v-for="role in this.allRoles" :key="role">
            <a @click="changeRole(role)">
              {{ role }}
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </a-layout-sider>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import find from 'lodash/find'
// import vueCustomScrollbar from 'vue-custom-scrollbar'
import { getMenuData } from '@/services/menu'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
  name: 'menu-left',
  components: {
    // vueCustomScrollbar,
    SubMenu,
    Item,
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.openKeys = store.get('app.menu.openedKeys') || []
    this.selectedKeys = store.get('app.menu.selectedKeys') || []
    this.setSelectedKeys()
    this.handleOpenChange(['dashboard'])
    this.getAllRole()
  },
  data() {
    return {
      menuData: getMenuData,
      selectedKeys: [],
      openKeys: [],
      user_name: this.$store.state.user.name,
      role: this.$store.state.user.role,
      allRoles: this.$store.state.user.allRoles,
      isWidthSmall: window.screen.width <= 320,
    }
  },
  watch: {
    'settings.isMenuCollapsed'() {
      this.openKeys = []
    },
    $route() {
      this.setSelectedKeys()
    },
  },
  methods: {
    onCollapse: function (collapsed, type) {
      const value = !this.settings.isMenuCollapsed
      this.$store.commit('CHANGE_SETTING', {
        setting: 'isMenuCollapsed',
        value,
      })
    },
    handleClick(e) {
      if (e.key === 'settings') {
        this.$store.commit('CHANGE_SETTING', {
          setting: 'isSettingsOpen',
          value: true,
        })
        return
      }
      store.set('app.menu.selectedKeys', [e.key])
      this.selectedKeys = [e.key]
    },
    handleOpenChange(openKeys) {
      store.set('app.menu.openedKeys', openKeys)
      this.openKeys = openKeys
    },
    setSelectedKeys() {
      const pathname = this.$route.path
      const menuData = this.menuData.concat()
      const flattenItems = (items, key) =>
        items.reduce((flattenedItems, item) => {
          flattenedItems.push(item)
          if (Array.isArray(item[key])) {
            return flattenedItems.concat(flattenItems(item[key], key))
          }
          return flattenedItems
        }, [])
      const selectedItem = find(flattenItems(menuData, 'children'), [
        'url',
        pathname,
      ])
      this.selectedKeys = selectedItem ? [selectedItem.key] : ['dashboard']
    },
    logout() {
      this.$store.dispatch('user/LOGOUT')
    },
    getAllRole() {
      this.allRoles = window.localStorage.getItem('allRoles').split(',')
    },
    changeRole(role) {
      const index = this.allRoles.indexOf(role)
      window.localStorage.setItem('role', this.allRoles[index])
      window.location.href = '/'
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
