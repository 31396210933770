<template>
  <!-- <a-dropdown :trigger="['click']" placement="bottomLeft" @visibleChange="addCount"> -->
  <div class="d-flex justify-content-between align-items-center">
    <a-dropdown :trigger="['click']" placement="bottomLeft" class="mr-5">
      <div :class="$style.dropdown">
          <!-- <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" /> -->
          <div class="d-flex align-items-center">
          <div :class="$style.name">{{ this.role }}</div>
          </div>
      </div>
      <a-menu slot="overlay">
        <a-menu-item v-for="role in this.allRoles" :key="role">
          <a @click="changeRole(role)">
            {{ role }}
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-dropdown :trigger="['click']" placement="bottomLeft">
      <div :class="$style.dropdown">
          <!-- <a-avatar shape="square" icon="user" size="large" :class="$style.avatar" /> -->
          <div class="d-flex align-items-center">
            <img
             src="resources/images/user-icon.svg"
             alt="avatar"
             width="25"
             height="25"
             :class="$style.avatar"
           />
           <div :class="$style.name">{{ this.user_name }}</div>
          </div>
      </div>
      <a-menu slot="overlay">
        <!-- <a-menu-item>
          <a href="javascript: void(0);" @click="() => { $router.push('/profile/account') }">
            <i class="fe fe-user mr-2"></i>
            {{ $t('topBar.profileMenu.editProfile') }}
          </a>
        </a-menu-item>
        <a-menu-divider /> -->
        <a-menu-item>
          <a href="javascript: void(0);" @click="logout">
            <i class="fe fe-log-out mr-2"></i>
            {{ $t('topBar.profileMenu.logout') }}
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      count: 7,
      user_name: this.$store.state.user.name,
      role: this.$store.state.user.role,
      allRoles: this.$store.state.user.allRoles,
    }
  },
  mounted() {
    this.getAllRole()
  },
  methods: {
    getAllRole() {
      this.allRoles = window.localStorage.getItem('allRoles').split(',')
    },
    addCount() {
      this.count++
    },
    logout() {
      this.$store.dispatch('user/LOGOUT')
    },
    changeRole(role) {
      const index = this.allRoles.indexOf(role)
      window.localStorage.setItem('role', this.allRoles[index])
      window.location.href = '/'
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
