import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    settings,
  },
  state: {
    patientData: [],
    cityFilters: [],
    doctorFilters: [],
    pharmacyFilters: [],
    statusFilters: [],
    deathCauseFilters: [],
    articleMonthFilters: [],
    articleData: [],
    totalDoT: 0,
    meanDoT: 0,
    medianDoT: 0,
  },
  mutations: {},
  actions: {},
})
